import Keycloak from "keycloak-js";
import Filters from "../../helpers/DataHelpers/Filters";

class AuthService {
  constructor(authUrl, realm, clientId, authLogoutRedirectUrl, authRtokenRefreshInterval) {
    this.authUrl = authUrl;
    this.realm = realm;
    this.clientId = clientId;
    this.authLogoutRedirectUrl = authLogoutRedirectUrl;
    this.tokenRefreshInterval = authRtokenRefreshInterval;
    this.keycloak = new Keycloak({
      url: this.authUrl,
      realm: this.realm,
      clientId: this.clientId,
      pkceMethod: "S256",
    });
    this.loginInitiated = false;
  }

  async login() {
    if (this.loginInitiated) {
      return Promise.resolve();
    }
    this.loginInitiated = true;
    return await this.keycloak.init({ onLoad: "login-required", checkLoginIframe: false, pkceMethod: "S256" });
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    return this.keycloak.logout({ redirectUri: this.authLogoutRedirectUrl });
  }

  async authenticated() {
    return await this.keycloak.authenticated;
  }

  async getProfile() {
    return await this.keycloak.loadUserInfo();
  }

  getAccessToken() {
    return this.keycloak.token;
  }

  isTokenExpired() {
    return this.keycloak.isTokenExpired();
  }

  async updateToken() {
    await this.keycloak.updateToken(this.tokenRefreshInterval);
    return true;
  }

  authorize(allowedRoles) {
    const roles = this.keycloak.tokenParsed?.resource_access[this.clientId].roles;
    return Filters.includesAny(allowedRoles, roles);
  }
}

export default AuthService;
